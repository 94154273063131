import FlexSearch from 'flexsearch';
import { create } from 'zustand';
import { Obj, Sort, Str } from '@pkg/utils';

const InitialIndex = new FlexSearch.Index({
  tokenize: 'forward',
  context: true,
});

/**
 * @param {Index} index
 * @param {Map} map
 * @returns {Index}
 */
const updateIndex = (index, map) => {
  if (!Obj.isEmpty(index.register)) {
    for (const key in index.register) {
      if (!map.has(key)) {
        index.remove(key);
      }
    }
  }

  map.forEach(({ title }, key) => {
    if (!index.register?.[key]) {
      index.add(key, title);
    }
  });

  return index;
};

/**
 * @param {Object[]} list
 * @param {Index} index
 * @returns {Object}
 */
export const storeRoles = (list, index = InitialIndex) => {
  const map = new Map();
  const titles = {
    default: new Map(),
    lower: new Map(),
  };

  const sorted = structuredClone(list).sort(Sort.alpha('titles'));
  // Creates a map using a hash id from the title stores all role
  // uuids that share the same name in an ids array.
  sorted.forEach((item) => {
    const id = item.uuid;
    const title = item.title;
    // For now we're hashing the titles for a simple id but this will be
    // replaced in the future with a role library.
    const hashId = Str.hash(title);

    const existing = map.get(hashId);
    if (existing) {
      existing.ids.add(id);
      return;
    }

    map.set(hashId, {
      hashId,
      ids: new Set([id]),
      title,
    });
    titles.default.set(title, hashId);
    titles.lower.set(title.toLowerCase(), hashId);
  });

  return {
    index: updateIndex(index, map),
    list: [...map.values()].map((item) => item),
    map: map,
    titles: titles,
  };
};

/**
 * @type {Function}
 * @param {Function} [getter]
 * @returns {any}
 */
const useStore = create((set, get) => ({
  index: InitialIndex,
  isLoading: true,
  list: [],
  map: new Map(),
  titles: {
    lower: new Map(),
    default: new Map(),
  },
  setLoading: (isLoading) => set(() => ({ isLoading })),
  setRoles: (list) => set(({ index }) => storeRoles(list, index)),
}));

export default useStore;
