import { useClient } from '@pkg/client';
import { useDatabase } from '@pkg/database';
import { ClientError } from '@/lib/enums';
import { RevisionFragment } from '../designs/fragments';
import parse from '../snapshots/utils/parse';

const GET_DESIGN_REVISION = /* GraphQL */ `
  query GetDesignRevision($revisionId: UUID!) {
    designRevision(uuid: $revisionId) {
      ...RevisionFragment
    }
  }

  ${RevisionFragment}
`;

export default function useFetch() {
  const client = useClient();
  const database = useDatabase();

  /**
   * @param {String} revisionId
   * @returns {Promise}
   */
  const fetch = async (revisionId) => {
    try {
      const result = await client.graphql({
        query: GET_DESIGN_REVISION,
        variables: { revisionId },
      });

      if (!result.data?.designRevision) {
        return { error: ClientError.NOT_FOUND };
      }

      const revision = result.data.designRevision;
      revision.snapshot = parse(revision.snapshot);
      revision.loaded_at = Date.now();

      await database.designRevisions.put(revision);
      return { revision };
    } catch (error) {
      if (error.isAuthorizationError?.()) {
        return { error: ClientError.NOT_AUTHORIZED };
      } else {
        return { error: ClientError.NOT_FOUND };
      }
    }
  };

  return fetch;
}
