import { Str } from '@pkg/utils';

export default function parse(snapshotString) {
  if (!snapshotString) {
    return null;
  }

  const snapshot = JSON.parse(snapshotString);
  snapshot.__hash = Str.hash(snapshotString);

  Object.keys(snapshot.entities ?? {}).forEach((type) => {
    snapshot.entities[type] = Object.values(snapshot.entities[type]);
  });

  return snapshot;
}
