import { Obj } from '@pkg/utils';

/**
 * Strip irrelevant data from the snapshot
 * @param {Object} model
 * @param {Object} model.snapshot
 * @returns
 */
export default function strip(model) {
  const stripped = structuredClone(model);
  const role = Obj.only(stripped.snapshot.entities.roles[0], [
    'uuid',
    'title',
    'fte',
    'tags',
    'updated_at',
  ]);

  stripped.snapshot.entities.roles[0] = role;
  return stripped;
}
