import * as Auth from '@pkg/auth';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import { Routes } from '@pkg/utils';
import {
  DesignEntity,
  DesignPermission,
  DesignRole,
  DesignType,
} from '@/lib/enums';
import parse from '../../snapshots/utils/parse';
import { DesignFragment } from '../fragments';
import useStore from '../useStore';

const CREATE_SCENARIO = /* GraphQL */ `
  mutation CreateScenario($input: CreateScenarioInput!) {
    createScenario(input: $input) {
      ...DesignFragment
    }
  }

  ${DesignFragment}
`;

export default function useCreateScenario() {
  const database = useDatabase();
  const { mutateAsync } = useMutation({ mutation: CREATE_SCENARIO });

  const { set } = Auth.useStore();
  const { put } = useStore();

  /**
   * @param {Object|undefined} scenario
   * @returns {Promise}
   */
  async function optimisticScenario(scenario) {
    if (!scenario) {
      return;
    }

    const revision = scenario.latest;
    revision.design = { uuid: scenario.uuid };
    revision.snapshot = parse(revision.snapshot);
    revision.loaded_at = Date.now();

    scenario.__pathname = Routes.build.design(scenario, revision.snapshot);
    scenario.__type = DesignEntity.SCENARIO;

    put(scenario);
    await Promise.all([
      database.designs.put(scenario),
      database.designRevisions.put(revision),
    ]);
  }

  /**
   * @param {Object} scenario
   * @returns {Promise}
   */
  async function optimisticAccess(scenario) {
    if (!scenario) {
      return;
    }

    const id = scenario.uuid;
    const me = await database.auth.get('me');
    me.scenarios.push({ uuid: id, type: DesignType.SCENARIO });
    me.access.roles.design.push(`${DesignRole.ADMIN}.${id}`);
    me.access.permissions.design.push(`${DesignPermission.MANAGE}.${id}`);
    me.access.permissions.design.push(`${DesignPermission.READ}.${id}`);
    me.access.permissions.design.push(`${DesignPermission.WRITE}.${id}`);

    set({ me });
    await database.auth.put(me);
  }

  /**
   * @param {Object} input
   * @param {String} [input.uuid]
   * @param {DesignScope} input.scope
   * @param {String} input.name
   * @param {String} [input.goal]
   * @param {Object} [input.from]
   * @param {String} input.from.design_uuid
   * @param {String} [input.from.entity_uuid]
   * @param {String} [input.mutation]
   * @returns {Promise}
   */
  async function createScenario(input) {
    const result = await mutateAsync(input);
    const scenario = result.data?.createScenario;
    await optimisticScenario(scenario);
    await optimisticAccess(scenario);
    return result;
  }

  return createScenario;
}
