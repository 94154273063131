import { useMemo } from 'react';
import parse from '@pkg/entities/snapshots/utils/parse';
import { useQuery } from '@pkg/graphql';

const SEARCH_LIBRARY_ROLES = /* GraphQL */ `
  query LibraryRolesSearch($search: String) {
    libraryRoles(query: $search) {
      data {
        uuid
        title
        enabled
        snapshot
      }
    }
  }
`;

/**
 * @param {String} search
 * @param {Boolean} enabled
 */
export default function useSearch(search, enabled = true) {
  const query = [SEARCH_LIBRARY_ROLES, { search }];
  const { data, dataUpdatedAt, isLoading } = useQuery({ query, enabled });

  return useMemo(() => {
    const results = (data?.libraryRoles?.data ?? []).map((item) => {
      const role = {};
      role.uuid = item.uuid;
      role.enabled = item.enabled;
      role.title = item.title;
      role.snapshot = parse(item.snapshot);
      return role;
    });
    return { results, isLoading };
  }, [dataUpdatedAt]);
}
