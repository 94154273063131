import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import parse from '@pkg/entities/snapshots/utils/parse';
import useStore from '../../useStore';

export default function useUpdateFromResult() {
  const database = useDatabase();
  const store = useStore();

  /**
   * @param {Object} result
   * @returns {Promise}
   */
  const handler = async (result) => {
    if (!result) {
      return;
    }

    const design = structuredClone(result);
    const latest = design.latest;
    design.latest = { uuid: latest.uuid };

    latest.snapshot = parse(latest.snapshot);
    latest.loaded_at = Date.now();

    store.update(design.uuid, design);
    await Promise.all([
      database.designs.update(design.uuid, design),
      database.designRevisions.put(latest),
    ]);
  };

  return useCallback(handler, [database, store.update]);
}
