import { useMemo } from 'react';
import parse from '@pkg/entities/snapshots/utils/parse';
import { useQuery } from '@pkg/graphql';

const GET_LIBRARY_ROLES = /* GraphQL */ `
  query GetLibraryRoles {
    me {
      organisation {
        roles {
          uuid
          title
          enabled
          snapshot
        }
      }
    }
  }
`;

const QUERY = Object.freeze([GET_LIBRARY_ROLES]);
export default function useLibraryRolesQuery() {
  const { data, dataUpdatedAt } = useQuery({ query: QUERY });
  return useMemo(() => {
    return (data?.me.organisation.roles ?? []).map((item) => {
      const role = {};
      role.uuid = item.uuid;
      role.title = item.title;
      role.enabled = item.enabled;
      role.snapshot = parse(item.snapshot);
      return role;
    });
  }, [dataUpdatedAt]);
}
