export default function only(obj, keys) {
  const result = {};
  keys.forEach((key) => {
    if (Object.hasOwn(obj, key)) {
      result[key] = obj[key];
    }
  });

  return result;
}
